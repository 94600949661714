<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="id"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-combobox
      v-if="isSolo"
      :attach="attach"
      v-model="val"
      :filter="filter"
      dense
      :items="items"
      :item-text="item_text"
      :item-value="item_value"
      :disabled="!editable"
      :success="valid"
      :filled="editable"
      :error-messages="errors"
      :label="label"
      @input="$emit('onInput', { name, value: val })"
      :data-testid="testid"
      :autofocus="autofocus"
      :placeholder="placeholder"
    >
      <template v-if="isChangeMessage" v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ noDataText }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    <v-autocomplete
      v-if="!isSolo"
      :attach="attach"
      v-model="val"
      :filter="filter"
      dense
      :items="items"
      :item-text="item_text"
      :item-value="item_value"
      :disabled="!editable"
      :success="valid"
      :filled="editable"
      :error-messages="errors"
      :label="label"
      @input="$emit('onInput', { name, value: val })"
      :data-testid="testid"
      :autofocus="autofocus"
      :placeholder="placeholder"
    >
      <template v-if="isChangeMessage" v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ noDataText }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-action>
          <v-btn color="primary" @click="$emit('handleAction')">
            {{ actionBtnText }}
          </v-btn>
        </v-list-item-action>
      </template>
    </v-autocomplete>
    <div class="pinAttach" :id="pinId"></div>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    isSolo: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    validation_rules: {
      type: String,
      default: "",
    },
    validation_label: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
    },
    item_text: {
      type: String,
      default: "name",
    },
    item_value: {
      type: String,
      default: "id",
    },
    id: {
      type: String,
    },
    pinId: {
      type: String,
      default: "",
    },
    pinIdSpecial: {
      type: String,
      default: "",
    },
    filterCustom: {
      type: Boolean,
      default: false,
    },
    isChangeMessage: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No data available",
    },
    actionBtnText: {
      type: String,
      default: "新規登録",
    },
  },
  methods: {
    customFilter(item, queryText) {
      const itemName = item.name;
      let searchText = queryText;
      if (searchText.match(/(?<=\S)\s(?=\S)/g)) {
        return itemName.indexOf(searchText) > -1;
      } else {
        searchText = searchText.trim();
        return itemName.replace(/ +/g, "").indexOf(searchText) >= 0;
      }
    },
  },
  computed: {
    attach() {
      if (this.pinId !== "" || this.pinIdSpecial !== "") {
        return this.pinId === this.pinIdSpecial
          ? `#${this.pinIdSpecial}`
          : `#${this.pinId}`;
      } else {
        return undefined;
      }
    },
    filter() {
      return this.filterCustom ? this.customFilter : undefined;
    },
  },
  data: () => {
    return {
      val: "",
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.pinAttach {
  position: absolute;
  bottom: 82px;
}
.v-input ::v-deep .v-input__slot {
  border: 1px solid $border_color;
  border-radius: 5px;
  color: transparent;
  background-color: $primary_color !important;
  box-shadow: 0 5px 10px $shadow_color;
}
.v-input ::v-deep .v-input__slot:before {
  border-color: transparent !important;
}
.v-input ::v-deep .v-icon {
  color: $icon_color !important;
}
.v-input ::v-deep input::placeholder {
  color: $placeholder_color !important;
}
.v-input ::v-deep input {
  color: $data_color !important;
}
.v-input--is-focused ::v-deep input::placeholder {
  opacity: 0;
}
::v-deep .v-input--is-disabled .v-input__slot {
  padding: 6px 12px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
  .v-icon {
    display: none;
  }
}
::v-deep .v-input--is-disabled input {
  color: $disable_data_color !important;
}
.v-input.v-input--is-disabled ::v-deep .v-icon {
  color: $disable_icon_color !important;
}
</style>
