import Api, { Mock } from "../api";
import mock_user from "./mock/users.json";
import mock_user_detail from "./mock/user_detail.json";

const URL = `${process.env.VUE_APP_API_BASE_URL}/users`;
const GET_LIST_URL = `${URL}/get_list_users`;
const DELETE_URL = `${URL}/delete_users`;
const GET_DETAIL_URL = `${URL}/get_user_info`;
const CHECK_EMAIL_EXITS = `${URL}/check_email_exits`;
const CREATE_URL = `${URL}/add_user_info`;
const UPDATE_URL = `${URL}/update_user_info`;
const GET_LIST_USER_ASIGN_URL = `${URL}/get_list_users_for_asign_to_fields`;
const INVITE_EMPLOYEE_URL = `${URL}/invite_users`;

if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_user,
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_user_detail,
  });

  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPut(URL).reply(200, {
    status_code: 200,
    message: "",
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(GET_LIST_USER_ASIGN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_user,
  });
  Mock.onPost(INVITE_EMPLOYEE_URL).reply(200, {
    status_code: 200,
    message: "招待しました",
    result: true,
    contents: {},
  });
}

export const users = {
  get: (params) => {
    return Api.get(GET_LIST_URL, { params });
  },
  getListUserForAsignToFields: (params) => {
    return Api.get(GET_LIST_USER_ASIGN_URL, { params });
  },
  getUserDetail: async (params) => {
    return Api.get(`${GET_DETAIL_URL}/${params}`);
  },
  checkEmailExits: (params) => {
    return Api.get(CHECK_EMAIL_EXITS, { params });
  },
  post: (params) => {
    return Api.post(CREATE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_URL, params);
  },
  put: async (params) => {
    return Api.put(URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  inviteUsers: async (params) => {
    return Api.post(INVITE_EMPLOYEE_URL, params);
  },
};
