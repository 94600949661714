<template>
  <v-container>
    <v-form autocomplete="off">
      <v-list-item>
        <v-list-item-content>
          <Label
            label="法人番号"
            :editable="comporateNumberEditable"
            :required="comporateNumberEditable"
          >
            <InputText
              name="corporate_number"
              class="corporate_number"
              :autofocus="autofocus"
              :values="formValues"
              :editable="comporateNumberEditable"
              placeholder="法人番号"
              validation_label="法人番号"
              :validation_rules="comporateNumberRequired"
              @onInput="onInput"
            />
          </Label>
          <div class="link-container">
            <label>法人番号の検索はこちらへ</label>
            <a
              href="https://www.houjin-bangou.nta.go.jp/"
              target="_blank"
              class="link"
              >{{ COMPORATE_SITE }}</a
            >
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <!-- 法人格 -->
          <Label label="法人格" :editable="editable" required>
            <SelectWithFilter
              name="corporate_type"
              :items="COPORATE_PREFIX_ARR"
              :editable="editable"
              :values="formValues"
              validation_label="法人格"
              validation_rules="required"
              @onInput="onInput"
            />
          </Label>
        </v-list-item-content>
      </v-list-item>
      <!-- 会社名 -->
      <v-list-item>
        <v-list-item-content>
          <Label label="会社名" :editable="editable" required>
            <InputText
              name="name"
              :values="formValues"
              :editable="editable"
              placeholder="会社名"
              validation_label="会社名"
              validation_rules="required|max:255"
              @onInput="onInput"
            />
          </Label>
        </v-list-item-content>
      </v-list-item>
      <!-- フリガナ -->
      <v-list-item>
        <v-list-item-content>
          <Label label="フリガナ" :editable="editable" required>
            <InputText
              name="name_kana"
              :values="formValues"
              :editable="editable"
              placeholder="フリガナ"
              validation_label="フリガナ"
              validation_rules="required|kana|max:255"
              @onInput="onInput"
            />
          </Label>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <!-- 種別 -->
          <Label label="種別" :editable="editable">
            <RadioGroup
              v-if="editable"
              name="company_type"
              :values="formValues"
              :items="companyTypeList"
              :editable="!disableRadioRole"
              @onInput="onInput"
            />
            <span v-if="!editable">{{
              getRadioName(COMPANY_TYPE_ARR, formValues["company_type"])
            }}</span>
          </Label>
        </v-list-item-content>
      </v-list-item>
    </v-form></v-container
  >
</template>
<script>
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import RadioGroup from "../elements/RadioGroup";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter.vue";
import { COMPANY_TYPE_ARR, COPORATE_PREFIX_ARR } from "@/constants/COMPANY";
import { COMPANY_TYPE, COMPORATE_SITE } from "@/constants/COMMON";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      COMPORATE_SITE,
      formValues: {},
      COMPANY_TYPE_ARR,
      COPORATE_PREFIX_ARR,
      COMPANY_TYPE,
      STATE: Store.state.CmnMst.constants.entries,
      autofocus: false,
      comporateNumberRequired: "required|digit|max:13|min:13",
      comporateNumberEditable: false,
    };
  },
  components: {
    Label,
    InputText,
    RadioGroup,
    SelectWithFilter,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.editable,
      (newValue) => {
        this.autofocus = newValue;
        this.comporateNumberEditable = newValue;
        if (newValue && !this.isNewItem) {
          const focusElement = document.getElementsByTagName("input");
          // 法人番号
          focusElement[10].focus();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    companyType() {
      return Store.state.Login.user?.company_type;
    },
    disableRadioRole() {
      return (
        this.companyType === this.COMPANY_TYPE.PATNER ||
        this.companyType === this.COMPANY_TYPE.RENTAL
      );
    },
    companyTypeList() {
      if (this.companyType === this.COMPANY_TYPE.OWNER) {
        return this.COMPANY_TYPE_ARR.slice(1);
      } else {
        return this.COMPANY_TYPE_ARR;
      }
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      if (name == "corporate_type") {
        if (value == 10) {
          this.comporateNumberEditable = false;
          this.comporateNumberRequired = "";
          formValues["is_required_corporate_number"] = false;
          formValues["corporate_number"] = null;
        } else {
          this.comporateNumberEditable = true;
          this.comporateNumberRequired = "required|digit|max:13|min:13";
          formValues["is_required_corporate_number"] = true;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.link-container {
  font-size: 20px;
  color: $accent_color;
  display: flex;
  flex-direction: column;
}
.link {
  font-size: 24px;
}
</style>
