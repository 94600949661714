<template>
  <v-menu
    v-model="isShow"
    ref="menu"
    transition="scroll-y-transition"
    offset-y
    :nudge-top="4"
    :nudge-left="-5"
    min-width="auto"
    dense
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <SearchFormWrapper>
        <InputText
          name="pickDate"
          placeholder="日付を選択"
          :values="computedDateRange"
          :readonly="true"
          :editable="true"
          innerIcon="mdi-calendar-today"
          @click="isShow = !isShow"
        />
      </SearchFormWrapper>
    </template>
    <div>
      <v-date-picker
        v-model="val"
        :key="val[0]"
        locale="ja"
        :color="COLORS.CALENDAR_COLOR"
        :day-format="(val) => new Date(val).getDate()"
        :first-day-of-week="0"
        no-title
        scrollable
        dense
        range
        @change="onInput(val)"
      >
        <div class="navigation">
          <div class="d-flex justify-space-between">
            <v-btn outlined color="success" @click="setDateByType(3)">
              <v-icon color="primary"> mdi-chevron-left</v-icon>
              <span>前日</span>
            </v-btn>
            <v-btn color="primary" @click="setDateByType(1)">
              <span>今日</span>
            </v-btn>
            <v-btn outlined color="success" @click="setDateByType(2)">
              <span>翌日</span>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <div class="d-flex justify-space-between">
            <v-btn outlined color="success" @click="setDateByType(6)">
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span>前週</span>
            </v-btn>
            <v-btn color="primary" @click="setDateByType(4)">
              <span>今週</span>
            </v-btn>
            <v-btn outlined color="success" @click="setDateByType(5)">
              <span>翌週</span>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <div class="d-flex justify-space-between">
            <v-btn outlined color="success" @click="setDateByType(9)">
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span>前月</span>
            </v-btn>
            <v-btn color="primary" @click="setDateByType(7)">
              <span>今月</span>
            </v-btn>
            <v-btn outlined color="success" @click="setDateByType(8)">
              <span class="mg-c">翌月</span>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-date-picker>
      <div>
        <v-btn class="btn left-btn" @click="resetDate(new Date())"
          >クリア</v-btn
        >
        <v-btn class="btn right-btn" @click="isShow = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </div>
  </v-menu>
</template>
<script>
import { format, parseISO } from "date-fns";
import { COLORS } from "@/constants/COMMON";

// componets
import InputText from "@/components/forms/elements/InputText";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper.vue";

export default {
  data: () => {
    return {
      COLORS,
      val: [],
      dateString: {
        pickDate: "",
      },
      isShow: false,
    };
  },
  components: {
    InputText,
    SearchFormWrapper,
  },
  props: {
    values: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        let fromDate = newValue[0].inspection_date_from;
        let toDate = newValue[0].inspection_date_to;
        fromDate = fromDate.replace(/\//g, "-");
        toDate = toDate.replace(/\//g, "-");
        if (fromDate && toDate) {
          const newDate = [];
          newDate.push(fromDate);
          newDate.push(toDate);
          this.val = newDate;
        }
      },
      { immediate: true, deep: true }
    );
  },
  computed: {
    computedDateRange() {
      if (this.val.length > 0) {
        const fromDate = this.val[0] ? this.val[0] : "";
        const toDate = this.val[1] ? this.val[1] : "";
        let formattedFromDate = "";
        let formattedToDate = "";
        if (fromDate && !fromDate.includes("/")) {
          formattedFromDate = format(parseISO(fromDate), "yyyy/MM/dd");
        } else {
          formattedFromDate = fromDate;
        }
        if (toDate && !toDate.includes("/")) {
          formattedToDate = format(parseISO(toDate), "yyyy/MM/dd");
        } else {
          formattedToDate = toDate;
        }
        this.dateString.pickDate = `${formattedFromDate} 〜 ${formattedToDate}`;
      } else {
        this.dateString.pickDate = "";
      }
      return this.dateString;
    },
  },
  methods: {
    setDateByType(type) {
      let fromDate;
      let toDate;
      let startDateSelect = new Date(this.val[0]);
      let endDateSelect = new Date(this.val[1]);
      let current = new Date();
      let now = format(Date.now(), "yyyy/MM/dd");
      switch (type) {
        // case of button 今日 (date now)
        case 1:
          fromDate = now;
          toDate = now;
          break;
        // case of button 翌日 (+ 1 day)
        case 2:
          startDateSelect.setDate(startDateSelect.getDate() + 1);
          fromDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          toDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          break;
        // case of button 前日 (- 1 day)
        case 3:
          startDateSelect.setDate(startDateSelect.getDate() - 1);
          fromDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          toDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          break;
        // case of button 今週 (week now)
        case 4:
          fromDate = new Date(
            current.setDate(current.getDate() - current.getDay())
          );
          toDate = new Date(
            current.setDate(current.getDate() - current.getDay() + 6)
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 翌週 (+ 1 week)
        case 5:
          startDateSelect.setDate(startDateSelect.getDate() + 7);
          fromDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay()
            )
          );
          toDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay() + 6
            )
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 前週 (- 1 week)
        case 6:
          startDateSelect.setDate(startDateSelect.getDate() - 7);
          fromDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay()
            )
          );
          toDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay() + 6
            )
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 今月 (month now)
        case 7:
          startDateSelect = new Date(
            current.getFullYear(),
            current.getMonth(),
            1
          );
          fromDate = format(startDateSelect, "yyyy-MM-dd");
          endDateSelect = new Date(
            current.getFullYear(),
            current.getMonth() + 1,
            0
          );
          toDate = format(endDateSelect, "yyyy-MM-dd");
          break;
        // case of button 翌月 (+ 1 month)
        case 8:
          startDateSelect.setMonth(startDateSelect.getMonth() + 1);
          fromDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth(),
            1
          );
          toDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth() + 1,
            0
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 前月 (- 1 month)
        case 9:
          startDateSelect.setMonth(startDateSelect.getMonth() - 1);
          fromDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth(),
            1
          );
          toDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth() + 1,
            0
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
      }
      this.$emit("onInput", {
        name: "customDate",
        value: { fromVal: fromDate, toVal: toDate },
      });
    },
    onInput(val) {
      const [fromVal, toVal] = val;
      this.fromVal = format(new Date(fromVal), "yyyy/MM/dd");
      this.toVal = format(new Date(toVal), "yyyy/MM/dd");
      this.$emit("onInput", {
        name: "customDate",
        value: { fromVal: this.fromVal, toVal: this.toVal },
      });
    },
    resetDate(current_date) {
      let currentDate = format(new Date(current_date), "yyyy-MM-dd");
      this.$emit("onInput", {
        name: "customDate",
        value: { fromVal: currentDate, toVal: currentDate },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-text-field__slot input {
  width: 230px !important;
  font-size: 16px !important;
  &:hover {
    cursor: pointer;
  }
}
.btn {
  position: absolute;
  top: 0;
  background-color: transparent !important;
  height: 40px !important;
  min-width: 60px !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  color: $page_title_color;
}
.left-btn {
  left: 0;
}
.right-btn {
  right: 0;
}
.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 150px;
}
.v-picker ::v-deep .v-btn {
  font-size: 12px !important;
  font-weight: normal !important;
}
::v-deep .v-date-picker-table {
  padding: 0 !important;
  height: fit-content !important;
}
::v-deep .v-date-picker-header {
  left: 55px !important;
  width: 190px !important;
  padding: 0 !important;
  .v-date-picker-header__value {
    font-size: 16px !important;
  }
  .v-date-picker-header__value button {
    padding: 0 !important;
  }
  .v-btn {
    width: fit-content !important;
  }
}
::v-deep .v-text-field__details {
  display: none !important;
}
</style>
