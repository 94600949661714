var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrap"}},[_vm._l((_vm.compRows),function(page,keyPage){return (_vm.compRows[0].length != 0)?[_c('div',{staticClass:"container-fluid page-item"},[_c('div',{staticClass:"title"},[_c('h2',{staticClass:"wrap-text"},[_vm._v(_vm._s(_vm.objectMapping.middleTitle)+"月例検査表")])]),_c('div',{staticClass:"content"},[_vm._m(0,true),_vm._m(1,true),_c('div',{staticClass:"table"},[_c('table',{staticClass:"w-100"},[_vm._m(2,true),_c('tbody',[_vm._l((page),function(item,key){return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.inspection_details[0].stt)+" ")]),_c('td',{staticClass:"text-center td-height",staticStyle:{"rotate":"(-90deg)"},attrs:{"rowspan":item.inspection_details.length === 0
                        ? 1
                        : item.inspection_details.length}},[_c('div',{staticClass:"text-td-height"},[_c('span',{staticClass:"br-none mr-auto",domProps:{"innerHTML":_vm._s(_vm.tranText(item.test_item))}})])]),(item.inspection_details.length > 0)?[_c('td',{staticClass:"text-td"},[_c('div',{staticClass:"text-td"},[_c('span',[_vm._v(_vm._s(item.inspection_details[0].inspection_item))])])]),_c('td',{staticClass:"text-td"},[_c('div',{staticClass:"text-td"},[_c('span',[_vm._v(_vm._s(item.inspection_details[0].main_point))])])])]:_vm._e(),(item.inspection_details.length <= 0)?[_c('td'),_c('td')]:_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v("レ")])],2),(item.inspection_details.length > 0)?_vm._l((item.inspection_details),function(val,k){return (k > 0)?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(val.stt))]),_c('td',{staticClass:"text-td"},[_c('div',{staticClass:"text-td"},[_c('span',[_vm._v(_vm._s(val.inspection_item))])])]),_c('td',{staticClass:"text-td"},[_c('div',{staticClass:"text-td"},[_c('span',[_vm._v(_vm._s(val.main_point))])])]),_c('td',{staticClass:"text-center"},[_vm._v("レ")])]):_vm._e()}):_vm._e()]})],2)]),_vm._m(3,true)]),_vm._m(4,true)])])]:_vm._e()}),(_vm.compRows[0].length == 0)?[_c('div',{staticClass:"container-fluid page-item"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.objectMapping.middleTitle)+"月例検査表")])]),_c('div',{staticClass:"content"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"table"},[_c('table',{staticClass:"w-100"},[_vm._m(7),_c('tbody',[_vm._l((32),function(i){return _c('tr',[_c('td',[_vm._v(" "+_vm._s(i)+" ")]),_vm._m(8,true),_vm._m(9,true),_vm._m(10,true),_c('td',{staticClass:"text-center"},[_vm._v("レ")])])})],2)]),_vm._m(11)]),_vm._m(12)])])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"w-100 pt-1 table-header"},[_c('tr',[_c('td',{staticClass:"w-10 text-center"},[_c('p',{staticClass:"p-0"},[_vm._v("機 械 所")]),_c('p',{staticClass:"p-0"},[_vm._v("有 者 名")])]),_c('td'),_c('td',{staticClass:"w-10 text-center"},[_c('p',{staticClass:"p-0"},[_vm._v("点 検")]),_c('p',{staticClass:"p-0"},[_vm._v("会社名")])]),_c('td',{attrs:{"colspan":"3"}})]),_c('tr',[_c('td',{staticClass:"w-10 text-center"},[_c('p',{staticClass:"p-0"},[_vm._v("管理名")])]),_c('td'),_c('td',{staticClass:"w-10 text-center"},[_c('p',{staticClass:"p-0"},[_vm._v("仕 様")]),_c('p',{staticClass:"p-0"},[_vm._v("能 力")])]),_c('td'),_c('td',{staticClass:"w-10 text-center"},[_c('p',{staticClass:"p-0"},[_vm._v("機 械")]),_c('p',{staticClass:"p-0"},[_vm._v("番 号")])]),_c('td')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"titleContent"},[_c('li',[_vm._v("記入要領")]),_c('li',[_vm._v("レ：良好")]),_c('li',[_vm._v("×：調整または要修理")]),_c('li',[_vm._v("○：調整または補修したとき")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"text-center"},[_c('tr',[_c('th',{staticStyle:{"width":"20px"}},[_vm._v("番 "),_c('br'),_vm._v("号")]),_c('th',{staticStyle:{"width":"20px"}},[_vm._v("項 "),_c('br'),_vm._v("目")]),_c('th',{staticClass:"letter-spacing",staticStyle:{"width":"270px"}},[_vm._v(" 検 査 項 目 ")]),_c('th',{staticClass:"point"},[_c('p',{staticClass:"point-1 letter-spacing"},[_vm._v("主眼点")]),_c('p',{staticClass:"point-2 text-end letter-spacing"},[_vm._v("検査月日")])]),_c('th',{staticStyle:{"width":"50px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"w-100"},[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"80%"}},[_vm._v(" 検 査 者 印 ま た は サ イ ン ")]),_c('td')]),_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"80%"}},[_vm._v(" 機械管理責任者印 またはサイン ")]),_c('td')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-table"},[_c('ul',{staticClass:"footer-table-ul"},[_c('li',[_vm._v(" 注１ 機械管理責任者は、１月以内ごとに１回月例検査を実施させる。 ")]),_c('li',[_vm._v(" 注２ 本検査表の検査事項の不足分については、補記し、不要分は抹消する。 ")]),_c('li',[_vm._v("注３ 補修したときは別紙に内容を記録する。")]),_c('li',[_vm._v(" 注４ 記入済の本検査表は、機械所有者において３年間保存する。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"w-100"},[_c('tr',[_c('td',[_vm._v("機 械 所")]),_c('td'),_c('td',[_vm._v("型 式")]),_c('td')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"titleContent"},[_c('li',[_vm._v("記入要領")]),_c('li',[_vm._v("レ：良好")]),_c('li',[_vm._v("×：調整または要修理")]),_c('li',[_vm._v("○：調整または補修したとき")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"text-center"},[_c('tr',[_c('th',{staticStyle:{"width":"20px"}},[_vm._v("番 "),_c('br'),_vm._v("号")]),_c('th',{staticStyle:{"width":"20px"}},[_vm._v("項 "),_c('br'),_vm._v("目")]),_c('th',{staticStyle:{"width":"270px"}},[_vm._v("検 査 項 目")]),_c('th',{staticClass:"point"},[_c('p',{staticClass:"point-1"},[_vm._v("主眼点")]),_c('p',{staticClass:"point-2 text-end"},[_vm._v("検査月日")])]),_c('th',{staticStyle:{"width":"50px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"w-100"},[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"80%"}},[_vm._v(" 検 査 者 印 ま た は サ イ ン ")]),_c('td')]),_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"80%"}},[_vm._v(" 機械管理責任者印 またはサイン ")]),_c('td')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-table"},[_c('ul',{staticClass:"footer-table-ul"},[_c('li',[_vm._v(" 注１ 機械管理責任者は、１月以内ごとに１回月例検査を実施させる。 ")]),_c('li',[_vm._v(" 注２ 本検査表の検査事項の不足分については、補記し、不要分は抹消する。 ")]),_c('li',[_vm._v("注３ 補修したときは別紙に内容を記録する。")]),_c('li',[_vm._v(" 注４ 記入済の本検査表は、機械所有者において３年間保存する。 ")])])])
}]

export { render, staticRenderFns }