<template>
  <v-form autocomplete="off">
    <v-container>
      <div class="reject">解除ユーザー</div>
    </v-container>
    <v-toolbar flat>
      <div class="center-title">検索結果 {{ rejectedUserList.length }}件</div>
    </v-toolbar>
    <v-data-table
      hide-default-footer
      fixed-header
      disable-sort
      :headers="TABLE_LABELS"
      :items="rejectedUserList"
      :height="200"
      :items-per-page="rejectedUserList.length"
      noDataText="データがありません。"
    >
      <template v-slot:[`item.button`]="{ item }">
        <v-btn class="reject-icon" icon @click="deleteReject(item)">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ handleRole(item.role) }}
      </template>
    </v-data-table>
  </v-form>
</template>
<script>
import {
  FORM_SITE_TABLE_LABELS,
  FIELD_USER_ROLE_ARRAY,
} from "@/constants/FIELD_USER";

// Components
import Label from "@/components/forms/elements/Label";

const TABLE_LABELS = FORM_SITE_TABLE_LABELS;
export default {
  components: {
    Label,
  },
  data() {
    return {
      TABLE_LABELS,
      rejectedUserList: [],
    };
  },
  props: {
    reject: Array,
  },
  mounted() {
    this.$watch(
      () => this.reject,
      (data) => {
        this.rejectedUserList = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {},
  methods: {
    handleRole(role) {
      return role == FIELD_USER_ROLE_ARRAY[1].value
        ? FIELD_USER_ROLE_ARRAY[1].name
        : "";
    },
    deleteReject(item) {
      let index = this.rejectedUserList.findIndex((el) => {
        return el.id === item.id;
      });
      if (index >= 0) {
        this.$delete(this.rejectedUserList, index);
      }
      this.$emit("formUpdate", 2, item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.center-title {
  margin-left: 45%;
  font-weight: bold;
  font-size: 20px;
}
.reject {
  color: $warning_color !important;
  font-size: 18px;
  font-weight: bold;
}
.reject-icon {
  background-color: $warning_color;
  width: 30px !important;
  height: 30px !important;
  .v-icon {
    color: $primary_color !important;
  }
}
</style>
