<template>
  <div class="d-flex">
    <v-tabs v-model="val">
      <v-tab
        v-for="item in items"
        :key="item.id"
        @click="$emit('onInput', item.id)"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <slot></slot>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      val: 0,
    };
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: [],
    },
    values: {
      type: Object,
      default: {},
    },
    isInspection: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        // 実績画面の順番が違う為、処理しない
        if (this.isInspection) return;
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
  methods: {},
};
</script>
