<template>
  <div>
    <GlobalDrawer width="500px">
      <!-- 自社 or 現場ポータルでコンテンツ切り分け -->
      <!-- 共通 -->
      <v-list-item>
        <v-icon class="mr-2" color="primary">mdi-account-circle-outline</v-icon>
        {{ userName }}
        <v-spacer></v-spacer>
        <v-icon @click="goHomePage">mdi-home</v-icon>
      </v-list-item>
      <!-- 共通 -->
      <v-list-item>
        <v-spacer></v-spacer>
        <v-btn @click="logout" color="primary">ログアウト</v-btn>
      </v-list-item>
    </GlobalDrawer>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import { MENU_DRAWER_ARRAY } from "@/constants/GLOBALHEADER";

// Components
import GlobalDrawer from "@/components/common/GlobalDrawer";
import Label from "@/components/forms/elements/Label";

/**
 * ポータル共通 初期値
 */

export default {
  data() {
    return {
      MENU_DRAWER_ARRAY,
    };
  },

  components: {
    GlobalDrawer,
    Label,
  },

  async mounted() {},

  computed: {
    USER_LOGIN() {
      return Store.state.Login.user;
    },
    // 氏名
    userName() {
      return `${this.USER_LOGIN.name_sei} ${this.USER_LOGIN.name_mei}`;
    },
  },

  methods: {
    // ログアウト
    async logout() {
      await Store.dispatch("Login/logout");
      Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
      Store.dispatch("Drawer/close");
      // login画面に遷移
      this.$router.push("/");
    },

    goHomePage() {
      Store.dispatch("Drawer/close");
      Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
      this.$router.push("/master/machines").catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
  },
};
</script>
