<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-text-field
      :flat="forResetEmail"
      :solo="forResetEmail"
      v-model="val"
      dense
      :disabled="!editable"
      :filled="editable"
      :error-messages="errors"
      :success="valid"
      :suffix="suffix"
      :maxlength="maxlength"
      :clearable="clearable"
      :autofocus="autofocus"
      :prepend-inner-icon="innerIcon"
      :readonly="readonly"
      :placeholder="editable ? placeholder : ''"
      @input="$emit('onInput', { name, value: val })"
      @change="$emit('onChanged', { name, value: val })"
      @click="$emit('click')"
      :data-testid="testid"
    ></v-text-field>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    testid: {
      type: String,
    },
    suffix: {
      type: String,
      default: "",
    },
    innerIcon: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    isForResetEmail: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      val: "",
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
  computed: {
    forResetEmail() {
      return this.isForResetEmail == true ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.v-input ::v-deep .v-input__slot {
  border: 1px solid $border_color;
  border-radius: 5px;
  color: transparent;
  background-color: $primary_color !important;
  box-shadow: 0 5px 10px $shadow_color;
  .v-text-field__suffix {
    color: $placeholder_color;
  }
}
.v-input ::v-deep .v-input__slot:before {
  border-color: transparent !important;
}
.v-input ::v-deep .v-icon {
  color: $icon_color !important;
}
.v-input ::v-deep input::placeholder {
  color: $placeholder_color !important;
}
.v-input--is-focused ::v-deep input::placeholder {
  opacity: 0;
}
::v-deep .v-input--is-disabled .v-input__slot {
  padding: 6px 12px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
}
</style>
